.App {
  text-align: center;
}

body{
  background-color: #282c34;
}

@media only screen and (max-width:640px) {
  .App-header {
    margin-top: -100px;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes strike{
  0%   { width : 0; }
  100% { width: 100%; }
}

.text{
  font-weight: 300;
}

.strike {
  position: relative;
}
.strike::after {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 0;
  width : 0;
  height: 12px;
  background: white;
  animation-name: strike;
  animation-duration: 0.7s;
  animation-delay: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; 
}

@keyframes appear{
  from { opacity: 0; }
  to { opacity: 1; }
}
.appear_1 {
  opacity: 0;
  animation: appear 2s linear 3s 1 normal forwards;
}

.appear_2 {
  opacity: 0;
  animation: appear 2s linear 4.5s 1 normal forwards;
  font-weight: 500;
}

.flag_appear{
  opacity: 0;
  animation: appear 2s linear 6s 1 normal forwards;
}

a.article_link{
  color: white;
  text-decoration: none;
}

.article_link:hover {
  text-decoration:none; 
  color: #0038b8;
}


